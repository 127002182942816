import React, { useState, useEffect } from "react";
import { useRouter } from "../../../components";
import { useGet } from "../../../components";
import { Item } from "../../items/item";
import { NotFound } from "../../../components";
import env from "../../../env";
import { useStore } from "../../../store";
import { ErrorPopup, Loading } from "../../../components";
import { useInstagram } from "../../../components";
import { MetaTag } from "../../../components";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { gtm } from "../../../lib/tracking";
import { createPortal } from "react-dom";

const log = (...args) => {
  // console.log(
  //   "%c [ args ]-17",
  //   "font-size:13px; background:#C0CF67; color:#495300;",
  //   args
  // );
};
const areEqual = (prevProps, nextProps) => {
  return prevProps.pageData === nextProps.pageData;
};
const PageMemo = React.memo(Page, areEqual);

function Portal({ children }) {
  const [el] = useState(document.createElement("div"));
  el.className = "portal";
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function WinePageViewPage() {
  const { dispatch } = useStore();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [data, setData] = useState(null);
  //const path = usePath();
  //log('view page', path);
  const { pathname, Redirect } = useRouter();
  useInstagram();

  const get = useGet();
  log("0. viewpage ready");

  useEffect(() => {
    log("*. start loading");
    setPageLoaded(false);
    get.send(env.apiBase + "/api/cms/GetPublishedPage?path=" + pathname);
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    if (data) {
      dispatch({ type: "SET_PAGE", payload: data.data });
      dispatch({ type: "SET_MENU", payload: data.menu });
    }
    // eslint-disable-next-line
  }, [data]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }
  log("1. viewpage component", pageLoaded, get.response);

  const pageData = get.response.data;
  const redirectTo = get.response.redirectTo;

  const cmsOption = {};
  log(pageLoaded, !pageLoaded && !!pageData);
  if (!pageLoaded && pageData) {
    log("2. dispatching page & menu");
    setPageLoaded(true);
    setData(get.response);
    document.title = pageData.pageTitle;
    gtm.pageView(pathname, pageData.pageTitle);
    return null;
  } else if (redirectTo) {
    if (redirectTo.indexOf("http") >= 0 && typeof window !== `undefined`) {
      return (window.location.href = redirectTo);
    } else {
      return <Redirect to={redirectTo} />;
    }
  } else if (!pageLoaded && !pageData) {
    setPageLoaded(true);
    setData(get.response);
    return <NotFound />;
  }

  log("3. call rendering memo");
  if (pageData) return <PageMemo pageData={pageData} cmsOption={cmsOption} />;
  else return <NotFound />;
}

function Page(props) {
  const pageData = props.pageData;
  const cmsOption = props.cmsOption;
  cmsOption.pageData = pageData;
  const [modalOpen, setModalOpen] = useState(true);
  const [no, setNo] = useState(false);
  log("4. page rendering", pageData, cmsOption);

  useEffect(() => {
    const open_state = window.localStorage.getItem("MODAL_OPEN");
    if (open_state !== null) setModalOpen(JSON.parse(open_state));
  }, []);

  useEffect(() => {
    window.localStorage.setItem("MODAL_OPEN", JSON.stringify(modalOpen));
  }, [modalOpen]);

  const sectionItems = pageData.sections.map((section, index) => {
    return (
      <Section
        item={section}
        key={section.sectionName + index}
        cmsOption={cmsOption}
      ></Section>
    );
  });

  return (
    <>
      <MetaTag data={getMetaTag(pageData)} />
      <Portal>
        {modalOpen && (
          <div className='modal'>
            <div className='modal__content'>
              {no && (
                <div className='no_message'>
                  Sorry, you are not old enough to view this web page.
                </div>
              )}
              <div className='modal_logo'>
                <img src='/assets/logos/modal_image.png' alt='logo' />
              </div>
              <h3>You must be 18 years old to view this page.</h3>
              <p>Are you 18 years or older?</p>
              <div className='modal__btns'>
                <button className='btn_yes' onClick={() => setModalOpen(false)}>
                  Yes
                </button>
                <button
                  className='btn_no'
                  onClick={() => (
                    // eslint-disable-next-line
                    setModalOpen(true), setNo(true)
                  )}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}
      </Portal>
      <div className={`cmsPage cmsPage--${pageData.pageTypeCode}`}>
        {sectionItems}
      </div>
    </>
  );
}

function Section(props) {
  log("5. section rendering");
  const item = props.item;
  //const sectionName = item.sectionName;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  if (!subItems || subItems.length === 0) return <></>;
  const subItemsElts = subItems.map((subItem, index) => (
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  ));
  return <>{subItemsElts}</>;
}

function getMetaTag(pageData) {
  //const country = utils.site.isNZ? 'New Zealand' : 'Australia'
  const country = "Australia";
  const siteName = env.siteName;
  const titleSuffix = env.titleSuffix;

  return {
    title: pageData.pageTitle + " | " + titleSuffix,
    description: cmsUtils.payload(pageData, "Description"),
    keywords: cmsUtils.payload(pageData, "Keyword"),
    heroImageUrl: utils.site.fullUrl(cmsUtils.payload(pageData, "ImageUrl")),
    language: "English",
    country: country,
    siteName: siteName,
    url: utils.site.fullUrl(pageData.pageUrl),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  };
}
