import { css } from "@emotion/core";
import { mq } from "../../cssInJs";
export default {
  button: css(
    mq({
      position: "absolute",
      left: "1rem",
      lineHeight: "1.6rem",
      color: "#af5f27",
      fontSize: ["12px", null, "20px"],
    })
  ),
};
